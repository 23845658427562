import { h } from 'preact';

interface Props {
  classes?: string;
  isOutputCurrent: boolean;
  outputUrl: string;
  templateId: string;
}

const OutputFileLink = ({
  classes,
  isOutputCurrent,
  outputUrl,
  templateId
}: Props) => {
  const friendlyName = `${templateId}.csv`;
  //const downloadUrl = `/${campaignId}/output/${friendlyName}`;
  return (
    <span>
      <a class={classes || ''} href={outputUrl}>
        {friendlyName}
      </a>
      {!isOutputCurrent && ' (Outdated)'}
    </span>
  );
};

export default OutputFileLink;
