// Adapted from https://codepen.io/m4r1vs/pen/MOOxyE

import { h, Component } from 'preact';

import {
  actions,
  background,
  calendar,
  chevron,
  chevronLeft,
  clearButton,
  datePicker,
  dayNames,
  days,
  opened as openedClass,
  scroll,
  selected,
  selectYear,
  titles,
  today
} from './style.css';

interface Props {
  close: () => void;
  opened: boolean;
  selectedDate: (Date) => void;
}

interface State {
  currentDate: Date;
  displayedMonth: number;
  displayedYear: number;
  selectYearMode: boolean;
}

export default class DatePicker extends Component<Props, State> {
  private now = new Date();
  private years: number[];
  private months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  private monthsAbbreviated = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  private days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  private close() {
    this.props.close();
  }

  private dayClicked(e) {
    const element = e.target;

    if (element.innerHTML === '') {
      return false;
    }

    const date = new Date(element.getAttribute('date'));
    this.setState({ currentDate: date });
  }

  private getDaysByMonth(month: number, year: number) {
    let calendar = [];
    const firstDay = new Date(year, month, 1).getDay(); // first weekday of month
    const lastDate = new Date(year, month + 1, 0).getDate(); // last date of month

    let day: number | null = 0;

    // the calendar is 7 * 6 fields big, so 42 loops
    for (let i = 0; i < 42; i++) {
      if (i >= firstDay && day !== null) {
        day++;
      }
      if (day > lastDate) {
        day = null;
      }

      // append the calendar Array
      calendar.push({
        day: day === 0 || day === null ? null : day, // null or number
        date: day === 0 || day === null ? null : new Date(year, month, day), // null or Date()
        today:
          day === this.now.getDate() &&
          month === this.now.getMonth() &&
          year === this.now.getFullYear() // boolean
      });
    }

    return calendar;
  }

  private displayPrevMonth() {
    if (this.state.displayedMonth <= 0) {
      this.setState({
        displayedMonth: 11,
        displayedYear: this.state.displayedYear - 1
      });
    } else {
      this.setState({
        displayedMonth: this.state.displayedMonth - 1
      });
    }
  }

  private displayNextMonth() {
    if (this.state.displayedMonth >= 11) {
      this.setState({
        displayedMonth: 0,
        displayedYear: this.state.displayedYear + 1
      });
    } else {
      this.setState({
        displayedMonth: this.state.displayedMonth + 1
      });
    }
  }

  private displaySelectedMonth() {
    if (this.state.selectYearMode) {
      this.toggleYearSelector();
    } else {
      if (!this.state.currentDate) {
        return false;
      }
      this.setState({
        displayedMonth: this.state.currentDate.getMonth(),
        displayedYear: this.state.currentDate.getFullYear()
      });
    }
  }

  private toggleYearSelector() {
    this.setState({ selectYearMode: !this.state.selectYearMode });
  }

  private changeDisplayedYear(e) {
    const element = e.target;
    this.toggleYearSelector();
    this.setState({
      displayedYear: parseInt(element.innerHTML, 10),
      displayedMonth: 0
    });
  }

  private setDate() {
    this.props.selectedDate(this.state.currentDate);
    this.close();
  }

  private clear() {
    this.props.selectedDate(null);
    this.close();
  }

  public componentDidUpdate() {
    if (this.state.selectYearMode) {
      document.getElementsByClassName('selected')[0].scrollIntoView(); // works in every browser incl. IE, replace with scrollIntoViewIfNeeded when browsers support it
    }
  }

  public constructor() {
    super();

    this.dayClicked = this.dayClicked.bind(this);
    this.displayNextMonth = this.displayNextMonth.bind(this);
    this.displayPrevMonth = this.displayPrevMonth.bind(this);
    this.getDaysByMonth = this.getDaysByMonth.bind(this);
    this.changeDisplayedYear = this.changeDisplayedYear.bind(this);
    this.toggleYearSelector = this.toggleYearSelector.bind(this);
    this.displaySelectedMonth = this.displaySelectedMonth.bind(this);

    const startYear = 2019;
    const endYear = this.now.getFullYear() + 1;
    this.years = Array.from(
      {
        length: endYear - startYear
      },
      (_, i) => i + startYear
    );

    this.state = {
      currentDate: this.now,
      displayedMonth: this.now.getMonth(),
      displayedYear: this.now.getFullYear(),
      selectYearMode: false
    };
  }

  public render(
    { opened },
    { currentDate, displayedMonth, displayedYear, selectYearMode }
  ) {
    return (
      <div>
        <div class={[datePicker, opened && openedClass].join(' ')}>
          <div class={titles}>
            <h3
              style={{
                color: selectYearMode
                  ? 'rgba(255,255,255,.87)'
                  : 'rgba(255,255,255,.57)'
              }}
              onClick={this.toggleYearSelector}
            >
              {currentDate.getFullYear()}
            </h3>
            <h2
              style={{
                color: !selectYearMode
                  ? 'rgba(255,255,255,.87)'
                  : 'rgba(255,255,255,.57)'
              }}
              onClick={this.displaySelectedMonth}
            >
              {this.days[currentDate.getDay()]},{' '}
              {this.monthsAbbreviated[currentDate.getMonth()]}{' '}
              {currentDate.getDate()}
            </h2>
          </div>

          {!selectYearMode && (
            <nav>
              <i
                onClick={this.displayPrevMonth}
                class={`${chevron} ${chevronLeft} fas fa-chevron-left`}
              ></i>
              <h4>
                {this.months[displayedMonth]} {displayedYear}
              </h4>
              <i
                onClick={this.displayNextMonth}
                class={`${chevron} fas fa-chevron-right`}
              ></i>
            </nav>
          )}

          <div class={scroll}>
            {!selectYearMode && (
              <div class={calendar}>
                <div class={dayNames}>
                  {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map(day => (
                    <span>{day}</span>
                  ))}
                </div>

                <div onClick={this.dayClicked} class={days}>
                  {this.getDaysByMonth(
                    this.state.displayedMonth,
                    this.state.displayedYear
                  ).map(day => {
                    let daySelected = false;

                    if (currentDate && day.date) {
                      daySelected =
                        currentDate.toLocaleDateString() ===
                        day.date.toLocaleDateString();
                    }

                    return (
                      <span
                        class={`${day.today ? today : ''} ${
                          daySelected ? selected : ''
                        }`}
                        disabled={!day.date}
                        date={day.date}
                      >
                        {day.day}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}

            {selectYearMode && (
              <div class={selectYear}>
                {this.years.map(year => (
                  <span
                    class={year === displayedYear ? selected : ''}
                    onClick={this.changeDisplayedYear}
                  >
                    {year}
                  </span>
                ))}
              </div>
            )}

            {!selectYearMode && (
              <div class={actions}>
                <button class={clearButton} onClick={() => this.clear()}>
                  CLEAR
                </button>
                <button onClick={() => this.close()}>CANCEL</button>
                <button onClick={() => this.setDate()}>OK</button>
              </div>
            )}
          </div>
        </div>

        <div
          class={background}
          onClick={() => this.close()}
          style={{
            display: opened ? 'block' : 'none'
          }}
        />
      </div>
    );
  }
}
