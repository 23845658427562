export const baseApiUrl = process.env.BASE_API_URL;
export const basePrivateUrl = process.env.BASE_PRIVATE_URL;
export const baseStaticUrl = process.env.BASE_STATIC_URL;

export enum CampaignStatus {
  Archived = 'archived',
  Created = 'created',
  Executed = 'executed',
  Executing = 'executing',
  Queued = 'queued'
}

export enum CampaignType {
  Email = 'email',
  Mobile = 'mobile',
  Web = 'web'
}
