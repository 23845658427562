import { h, Component, ComponentChild } from 'preact';
import { route } from 'preact-router';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import { form, formGroup } from '../../components/shared-styles/form.css';
import { container } from '../../components/shared-styles/container.css';
import { primary } from '../../components/shared-styles/buttons.css';
import { setToken } from '../../shared/token';

interface State {
  email?: string;
  errorMessage?: string;
  password?: string;
}

const LOGIN = gql`
  mutation authenticate($email: String!, $password: String!) {
    authenticate(email: $email, password: $password) {
      token
    }
  }
`;

export default class Login extends Component<{}, State> {
  public state = {
    email: '',
    password: ''
  };

  private handleSubmit = (e, mutation): void => {
    e.preventDefault();
    mutation();
  };

  public render({}, { email, errorMessage, password }): ComponentChild {
    return (
      <Mutation
        mutation={LOGIN}
        onCompleted={(data): void => this.confirm(data)}
        onError={(data): void => this.error(data)}
        variables={{ email, password }}
      >
        {(mutation): ComponentChild => (
          <div class={container}>
            <form
              class={form}
              method="post"
              onSubmit={(e): void => this.handleSubmit(e, mutation)}
            >
              <h1>Log in</h1>
              <div class={formGroup}>
                <label>Email</label>
                <input
                  autofocus
                  name="email"
                  onChange={(e): void =>
                    this.setState({
                      email: (e.target as HTMLInputElement).value
                    })
                  }
                  required
                  type="email"
                  value={this.state.email}
                />
              </div>
              <div class={formGroup}>
                <label>Password</label>
                <input
                  name="password"
                  onChange={(e): void =>
                    this.setState({
                      password: (e.target as HTMLInputElement).value
                    })
                  }
                  required
                  type="password"
                  value={this.state.password}
                />
              </div>
              {errorMessage && <div>{errorMessage}</div>}
              <button class={primary} type="submit">
                Log in
              </button>
            </form>
          </div>
        )}
      </Mutation>
    );
  }

  private confirm({ authenticate }): void {
    setToken(authenticate);
    route('/campaigns');
  }

  private error(): void {
    this.setState({
      errorMessage: 'Log in failed'
    });
  }
}
