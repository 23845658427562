import { h, Component } from 'preact';
import { route } from 'preact-router';

import { Query } from 'react-apollo';

import Alert, { Type } from '../../../components/alert';
import Options from '../../../components/campaigns/options';
import TileList from '../../../components/campaigns/tile-list';
import Table from '../../../components/campaigns/table';

import { primary } from '../../../components/shared-styles/buttons.css';
import {
  container,
  leftContainer,
  rightContainer,
  viewStyleButton
} from './style.css';

import CAMPAIGN_QUERY from '../queries/list';

enum CampaignView {
  List,
  Tile
}

interface State {
  campaignView: CampaignView;
}

export default class Index extends Component<any, State> {
  public state = {
    campaignView: CampaignView.Tile
  };

  public render({}, { campaignView }) {
    const link = (
      <a class={primary} href="/campaigns/new">
        Create a new campaign
      </a>
    );
    return (
      <Query query={CAMPAIGN_QUERY}>
        {({ loading, error, data }) => {
          document.title = 'Campaigns - Phoji';
          if (loading) {
            return <div>Loading</div>;
          }
          if (error) {
            return <Alert type={Type.Error}>{error.message}</Alert>;
          }

          const { campaigns } = data;

          if (campaigns && campaigns.length) {
            return (
              <div class={container}>
                <div class={leftContainer}>
                  <Options onCreateCampaign={() => route('/campaigns/new')} />
                </div>
                <div class={rightContainer}>
                  {campaignView !== CampaignView.List && (
                    <button
                      class={viewStyleButton}
                      onClick={() =>
                        this.setState({ campaignView: CampaignView.List })
                      }
                    >
                      <i class="fas fa-th-list"></i>
                    </button>
                  )}
                  {campaignView !== CampaignView.Tile && (
                    <button
                      class={viewStyleButton}
                      onClick={() =>
                        this.setState({ campaignView: CampaignView.Tile })
                      }
                    >
                      <i class="fas fa-table"></i>
                    </button>
                  )}

                  {campaignView === CampaignView.Tile && (
                    <TileList campaigns={campaigns} />
                  )}
                  {campaignView === CampaignView.List && (
                    <Table campaigns={campaigns} />
                  )}
                </div>
              </div>
            );
          } else {
            return link;
          }
        }}
      </Query>
    );
  }
}
