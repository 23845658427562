import { h } from 'preact';
import { helpButton } from './style.css';

import helpImage from '../../assets/images/help.png';

interface Props {
  onClick: (e: any) => void;
}

const HelpButton = ({ onClick }: Props) => (
  <button class={helpButton} onClick={onClick}>
    <img src={helpImage} />
  </button>
);

export default HelpButton;
