import { h } from 'preact';
import { Link } from 'preact-router';
import {
  active,
  campaignName,
  container,
  dateContainer,
  inactive,
  statusContainer
} from './style.css';
import { CampaignStatus } from '../../../shared/constants';
import dateFormatter from '../../../shared/date-formatter';

export enum Type {
  Alert = 'alert',
  Error = 'error',
  Info = 'info',
  Warning = 'warning'
}

interface Props {
  campaignId: string;
  createdAt: string;
  name: string;
  status: CampaignStatus;
  updatedAt: string;
}

export default ({
  campaignId,
  createdAt,
  name,
  status,
  updatedAt
}: Props): JSX.Element => (
  <Link href={`/campaigns/${campaignId}`} class={container}>
    <div class={campaignName}>{name}</div>
    <div class={dateContainer}>
      <span>Created: {dateFormatter.format(new Date(createdAt))}</span>
      <span>Updated: {dateFormatter.format(new Date(updatedAt))}</span>
    </div>
    <div
      class={`${statusContainer} ${
        status == CampaignStatus.Archived ? inactive : active
      }`}
    >
      {status == CampaignStatus.Archived ? 'Archived' : 'Active'}
    </div>
  </Link>
);
