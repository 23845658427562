import { h, Component } from 'preact';
import {
  clearFilters,
  createButton,
  dateContainer,
  datePickerContainer,
  header,
  optionSection,
  searchTextbox,
  separator,
  textbox
} from './style.css';
import ToggleButton from '../../toggle-button';
import ToggleGroup from '../../toggle-group';
import DatePicker from '../../date-picker';

interface Props {
  onCreateCampaign: (e) => Promise<void>;
}

interface State {
  endDate?: Date;
  showEndDatePicker: boolean;
  showStartDatePicker: boolean;
  startDate?: Date;
}

export default class Options extends Component<Props, State> {
  public render(
    { onCreateCampaign },
    { endDate, showEndDatePicker, showStartDatePicker, startDate }
  ) {
    return (
      <div>
        <button class={createButton} onClick={onCreateCampaign}>
          Create a Campaign
        </button>

        <button class={clearFilters}>Clear Filters</button>

        <section class={optionSection}>
          <h1 class={header}>Date Range</h1>
          <ToggleGroup onStateChanged={state => console.log('state', state)}>
            <ToggleButton tag="created">Created</ToggleButton>
            <ToggleButton tag="updated">Updated</ToggleButton>
          </ToggleGroup>

          <div class={dateContainer}>
            <input
              class={textbox}
              onFocus={() => this.setState({ showStartDatePicker: true })}
              placeholder="mm/dd/yyyy"
              type="text"
              value={startDate ? startDate.toLocaleDateString() : ''}
            />
            <div class={datePickerContainer}>
              <DatePicker
                close={() =>
                  this.setState({
                    showStartDatePicker: false
                  })
                }
                opened={showStartDatePicker}
                selectedDate={startDate => this.setState({ startDate })}
              />
            </div>
            <span class={separator}>-</span>
            <input
              class={textbox}
              onFocus={() => this.setState({ showEndDatePicker: true })}
              placeholder="mm/dd/yyyy"
              type="text"
              value={endDate ? endDate.toLocaleDateString() : ''}
            />
            <div class={datePickerContainer}>
              <DatePicker
                close={() =>
                  this.setState({
                    showEndDatePicker: false
                  })
                }
                opened={showEndDatePicker}
                selectedDate={endDate => this.setState({ endDate })}
              />
            </div>
          </div>
        </section>

        <section class={optionSection}>
          <h1 class={header}>Active</h1>
          <ToggleGroup
            tagIfNull="all"
            onStateChanged={state => console.log('state', state)}
          >
            <ToggleButton tag="all">ALL</ToggleButton>
            <ToggleButton tag="active">Active</ToggleButton>
            <ToggleButton tag="archived">Archived</ToggleButton>
          </ToggleGroup>
        </section>

        <section class={optionSection}>
          <h1 class={header}>Search</h1>
          <input
            class={[textbox, searchTextbox].join(' ')}
            placeholder="Search Name or Label"
            type="text"
          />
        </section>
      </div>
    );
  }
}
