import { h, Component } from 'preact';

import style from './style.css';
import { baseStaticUrl } from '../../../shared/constants';
import { GET_CAMPAIGN } from '../../../queries/campaign';
import Alert, { Type } from '../../../components/alert';
import Upload from '../../../components/upload';
import { Query, withApollo } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import gql from 'graphql-tag';
import { Link } from 'preact-router';

interface Props {
  client: ApolloClient;
  campaignId: string;
}

interface State {
  bytesUploadedSoFar?: number;
  currentError?: Error;
  errorMessage?: string;
  lastLoadedBytes?: number;
  numberOfFilesLeft?: number;
  reachedStep3: boolean;
  showSkip: boolean;
  totalUploadInBytes?: number;
  uploadComplete: boolean;
  uploadInProgress: boolean;
  userCanceled: boolean;
}

const GET_PHOJIS = gql`
  query PhojiQuery($campaignId: String!, $cursor: String) {
    phojis(campaignId: $campaignId, cursor: $cursor) {
      page {
        name
      }
      cursor
    }
  }
`;

const PRESIGN = gql`
  mutation PresignPhojiUpload(
    $fileName: String!
    $fileSize: Int!
    $fileType: String!
    $campaignId: String!
  ) {
    presignPhojiUpload(
      fileName: $fileName
      fileSize: $fileSize
      fileType: $fileType
      campaignId: $campaignId
    ) {
      url
      expectedPhoji {
        name
      }
    }
  }
`;

class Index extends Component<Props, State> {
  private xhr: XMLHttpRequest | undefined;
  private uploadQueue: File[] = [];
  private steps = {
    one: {
      bytesUploadedSoFar: undefined,
      showSkip: true,
      totalUploadInBytes: undefined,
      uploadComplete: false,
      uploadInProgress: false
    },
    two: {
      bytesUploadedSoFar: 0,
      showSkip: false,
      userCanceled: false,
      uploadComplete: false,
      uploadInProgress: true
    },
    three: {
      bytesUploadedSoFar: undefined,
      reachedStep3: true,
      showSkip: false,
      totalUploadInBytes: undefined,
      userCanceled: false,
      uploadComplete: true,
      uploadInProgress: false
    }
  };

  public constructor() {
    super();
    this.state = this.steps.one;
  }

  private addNewPhojiToCache(phoji) {
    const { campaignId, client } = this.props;
    const { phojis } = client.readQuery({
      query: GET_PHOJIS,
      variables: { campaignId }
    });

    client.writeQuery({
      data: {
        phojis: {
          ...phojis,
          page: [phoji, ...phojis.page]
        }
      },
      query: GET_PHOJIS,
      variables: { campaignId }
    });
  }

  private cancel = () => {
    this.setState(
      () => ({
        bytesUploadedSoFar: undefined,
        totalUploadInBytes: undefined,
        userCanceled: true
      }),
      () => {
        this.uploadQueue = [];
        if (this.xhr) {
          this.xhr.abort();
        }
      }
    );
  };

  private uploadPhoji = async (file, url) =>
    new Promise((resolve, reject) => {
      this.xhr = new XMLHttpRequest();
      this.xhr.responseType = 'json';

      /*
        `loaded` is the total number of bytes for this upload. We allow
        for multiple uploads and care about overall bytes so we have to
        keep track of the previous loaded value.
      */
      this.xhr.upload.addEventListener('progress', ({ loaded, total }) => {
        const { bytesUploadedSoFar = 0, lastLoadedBytes = 0 } = this.state;
        this.setState(() => ({
          bytesUploadedSoFar: bytesUploadedSoFar + loaded - lastLoadedBytes,
          lastLoadedBytes: loaded === total ? 0 : loaded
        }));
      });

      this.xhr.addEventListener('readystatechange', e => {
        if (this.xhr && this.xhr.readyState === 4) {
          this.xhr.status === 200
            ? resolve(e)
            : reject(
                Error(
                  `Upload to ${url} failed. Server returned status code ${this.xhr.status} with status ${this.xhr.statusText}.`
                )
              );
        }
      });
      this.xhr.open('PUT', url);
      this.xhr.send(file);
    });

  private upload = async (files: File[]) => {
    this.uploadQueue = Array.from(files).reverse();

    const {
      client: { mutate },
      campaignId
    } = this.props;

    while (this.uploadQueue.length) {
      const file = this.uploadQueue.pop();

      this.setState({
        numberOfFilesLeft: this.uploadQueue.length + 1
      });

      const {
        data: { presignPhojiUpload }
      } = await mutate({
        mutation: PRESIGN,
        variables: {
          fileName: file.name,
          fileSize: file.size,
          fileType: file.type,
          campaignId
        }
      });

      const { url, expectedPhoji: phoji } = presignPhojiUpload;
      await this.uploadPhoji(file, url);
      this.addNewPhojiToCache(phoji);
    }
  };

  public render(
    { campaignId }: Props,
    {
      bytesUploadedSoFar,
      currentError,
      errorMessage,
      numberOfFilesLeft,
      reachedStep3,
      showSkip,
      totalUploadInBytes,
      uploadComplete,
      uploadInProgress
    }: State
  ) {
    return (
      <Query query={GET_CAMPAIGN} variables={{ campaignId }}>
        {({ data, error, loading }) => {
          if (loading) {
            return null;
          }
          if (error) {
            return <Alert type={Type.Error}>{error.message}</Alert>;
          }

          const { campaign } = data;

          return (
            <div class={style.container}>
              {!this.state.userCanceled && currentError && errorMessage ? (
                <Alert error={currentError} type={Type.Error}>
                  {errorMessage}
                </Alert>
              ) : null}
              <a href={`/campaigns/${campaignId}`} class={style.close}>
                <i class="fas fa-times"></i>
              </a>
              <div class={style.main}>
                <h1>{campaign.name}</h1>
                <h2>Upload Images</h2>
                <Upload
                  accept="image/*"
                  multiple={true}
                  onChange={files => {
                    this.setState(
                      () => ({
                        ...this.steps.two,
                        totalUploadInBytes: files.reduce(
                          (sum, { size }) => sum + size,
                          0
                        )
                      }),
                      async () => {
                        try {
                          await this.upload(files);
                          this.setState({ ...this.steps.three });
                        } catch (err) {
                          const errState = this.state.userCanceled
                            ? {
                                errorMessage: undefined,
                                currentError: undefined
                              }
                            : {
                                errorMessage: 'Error occurred during upload',
                                currentError: err
                              };

                          this.setState({
                            ...errState,
                            ...(reachedStep3
                              ? this.steps.three
                              : this.steps.one)
                          });
                        }
                      }
                    );
                  }}
                  percentComplete={Math.max(
                    0,
                    ((bytesUploadedSoFar || 0) / (totalUploadInBytes || 1)) *
                      100
                  )}
                  uploadInProgress={uploadInProgress}
                />
                {showSkip && !reachedStep3 && (
                  <a class={style.skipLink} href={`/campaigns/${campaignId}`}>
                    Skip Image Uploads
                  </a>
                )}
                {uploadComplete && (
                  <div class={style.complete}>
                    Upload Complete, you can upload more or continue
                  </div>
                )}
              </div>
              {uploadInProgress && (
                <button
                  class={`${style.button} ${style.cancel}`}
                  onClick={() => this.cancel()}
                >
                  Cancel
                </button>
              )}
              {uploadComplete && (
                <Link
                  href={`/campaigns/${campaignId}/templates`}
                  class={`${style.button} ${style.continue}`}
                >
                  Continue
                </Link>
              )}
              <Query query={GET_PHOJIS} variables={{ campaignId }}>
                {({ data, error, loading }) => {
                  if (loading) {
                    return null;
                  }
                  if (error) {
                    return <Alert type={Type.Error}>{error.message}</Alert>;
                  }

                  const { phojis } = data;

                  return (
                    <ul class={style.phojiList}>
                      {uploadInProgress &&
                        Array.from({ length: numberOfFilesLeft || 0 }).map(
                          (_, i) => {
                            return (
                              <li class="animated fadeIn">
                                <svg class={style.placeholder} />
                              </li>
                            );
                          }
                        )}
                      {phojis.page.map(({ name }) => (
                        <li class="animated fadeIn">
                          <img
                            class={style.placeholder}
                            src={`${baseStaticUrl}/${name}?s=100`}
                          />
                        </li>
                      ))}
                    </ul>
                  );
                }}
              </Query>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withApollo(Index);
