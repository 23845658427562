import { h, Component } from 'preact';

import { toggleButton } from './style.css';

interface Props {
  children: string;
  checked?: boolean;
  onToggle?: (senderTag: string, checked: boolean) => void;
  tag: string;
}

export default class ToggleButton extends Component<Props, {}> {
  public render({ checked, children, onToggle, tag }) {
    return (
      <span class={toggleButton}>
        <label>
          <input
            onChange={e => {
              if (onToggle) {
                onToggle(tag, (e.target as HTMLInputElement).checked);
              }
            }}
            type="checkbox"
            checked={checked}
          />
          <span>{children}</span>
        </label>
      </span>
    );
  }
}
