import { h } from 'preact';
import { baseStaticUrl } from '../../../shared/constants';

interface Props {
  classes?: string;
  dataFile: string;
}

const MessageDataFileLink = ({ classes, dataFile }: Props) => {
  if (!dataFile) {
    return null;
  }
  const friendlyName = dataFile.substring(dataFile.lastIndexOf('/') + 1);
  const downloadUrl = `${baseStaticUrl}/${dataFile}`;
  return (
    <a class={classes || ''} href={downloadUrl}>
      {friendlyName}
    </a>
  );
};

export default MessageDataFileLink;
