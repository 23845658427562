import { h, Component } from 'preact';
import { route } from 'preact-router';
import { Mutation, withApollo } from 'react-apollo';
import Downshift from 'downshift/preact';
import gql from 'graphql-tag';

import {
  formGroup,
  required
} from '../../../components/shared-styles/form.css';
import { blue } from '../../../components/shared-styles/buttons.css';
import { CampaignType } from '../../../shared/constants';
import debounce from '../../../shared/debounce';

import CAMPAIGN_QUERY from '../queries/list';

import {
  back,
  container,
  createButton,
  deleteLabelButton,
  form,
  existingLabels,
  typeaheadMenu
} from './style.css';

const CREATE_CAMPAIGN = gql`
  mutation createCampaign(
    $labels: [String!]
    $name: String!
    $type: CampaignType!
  ) {
    createCampaign(labels: $labels, name: $name, type: $type) {
      campaignId
      createdAt
      name
      status
      type
      updatedAt
    }
  }
`;

const TYPEAHEAD = gql`
  query Typeahead($prefix: String!) {
    labelTypeahead(prefix: $prefix) {
      value
    }
  }
`;

interface State {
  errorMessage: string;
  labels?: [string];
  name?: string;
  type: string;
  typeahead?: [string];
}

class NewCampaign extends Component<any, State> {
  private confirm({ createCampaign }) {
    route(`/campaigns/${createCampaign.campaignId}/images`);
  }

  private error(data) {
    this.setState({
      errorMessage: 'Campaign creation failed'
    });
  }

  private handleSubmit = (e, mutation) => {
    e.preventDefault();
    console.log('in handleSubmit');
    mutation();
  };

  private queryTypeahead = debounce(async (...[client, prefix]) => {
    const { data } = await client.query({
      query: TYPEAHEAD,
      variables: { prefix }
    });
    console.log('data', data);
    this.setState({
      typeahead: data.labelTypeahead
    });
  }, 500);

  private readTypeaheadFromCache = (client, prefix) => {
    // Unfortunately readQuery throws instead of returning undefined
    // https://github.com/apollographql/apollo-feature-requests/issues/1
    let result = undefined;
    try {
      result = client.readQuery({
        query: TYPEAHEAD,
        variables: { prefix }
      });
    } catch {
      // Intentionally left empty
    }
    return result;
  };

  private prefixHasEmptyResult = (client, prefix) => {
    for (let i = 0; i < prefix.length; i++) {
      const result = this.readTypeaheadFromCache(
        client,
        prefix.substring(0, i)
      );
      console.log('result in prefix check', result);
      if (result && result.labelTypeahead.length === 0) {
        return true;
      }
    }
    return false;
  };
  public render({ client }, { errorMessage, labels, name, type, typeahead }) {
    return (
      <Mutation
        mutation={CREATE_CAMPAIGN}
        onCompleted={data => this.confirm(data)}
        onError={data => this.error(data)}
        update={(store, { data: { createCampaign } }) => {
          const { campaigns } = store.readQuery({
            query: CAMPAIGN_QUERY
          });
          campaigns.unshift(createCampaign);
        }}
        variables={{ labels, name, type }}
      >
        {mutation => (
          <div class={container}>
            <a href="/campaigns" class={back}>
              <i class="fas fa-arrow-left"></i>
            </a>

            <form
              class={form}
              method="post"
              onSubmit={e => this.handleSubmit(e, mutation)}
            >
              <h1>Create Campaign</h1>
              <div class={formGroup}>
                <label class={required}>
                  <span>Name</span>
                  <input
                    autofocus
                    name="name"
                    onInput={({ target }) =>
                      this.setState({
                        name: (target as HTMLInputElement).value
                      })
                    }
                    placeholder="Enter a campaign name"
                    required
                    type="text"
                    value={name}
                  />
                </label>
              </div>
              <Downshift
                itemToString={item => (item && item.value) || ''}
                onChange={(selection, stateAndHelpers) => {
                  if (!selection) {
                    return;
                  }
                  const { value } = selection;
                  this.setState(
                    () => {
                      return {
                        labels: labels
                          ? [...new Set([value, ...labels])]
                          : [value]
                      };
                    },
                    () =>
                      stateAndHelpers.reset({
                        inputValue: null,
                        highlightedIndex: null,
                        selectedItem: null
                      })
                  );
                }}
              >
                {({
                  getInputProps,
                  getItemProps,
                  getLabelProps,
                  getMenuProps,
                  isOpen,
                  inputValue,
                  highlightedIndex,
                  reset,
                  selectedItem
                }) => (
                  <div class={formGroup}>
                    <label {...getLabelProps()}>Labels</label>
                    <input
                      {...getInputProps({
                        disabled: (labels || []).length === 10,
                        maxLength: 50,
                        onChange: ({ target }) => {
                          this.setState({ typeahead: [] });
                          const prefix = target.value;
                          const result = this.readTypeaheadFromCache(
                            client,
                            prefix
                          );

                          // If we're in the cache skip the debounce call
                          if (result) {
                            this.setState({
                              typeahead: result.labelTypeahead
                            });
                          } else if (
                            !this.prefixHasEmptyResult(client, prefix)
                          ) {
                            this.queryTypeahead(client, prefix);
                          }
                        },
                        onKeyPress: event => {
                          if (event.key == 'Enter') {
                            console.log('inputValue', inputValue);
                            this.setState({
                              labels: labels
                                ? [...new Set([inputValue, ...labels])]
                                : [inputValue]
                            });
                            reset({
                              inputValue: null,
                              highlightedIndex: null,
                              selectedItem: null
                            });
                          }
                        },
                        placeholder:
                          (labels || []).length === 10
                            ? 'Maximum of 10 labels'
                            : null
                      })}
                    />
                    <ul
                      {...getMenuProps({
                        class: typeaheadMenu
                      })}
                    >
                      {isOpen
                        ? (typeahead || []).map((item, index) => (
                            <li
                              {...getItemProps({
                                key: item.value,
                                index,
                                item,
                                style: {
                                  backgroundColor:
                                    highlightedIndex === index
                                      ? 'var(--secondary-blue-white)'
                                      : 'var(--secondary-white)',
                                  fontWeight:
                                    selectedItem === item ? 'bold' : 'normal'
                                }
                              })}
                            >
                              {item.value}
                            </li>
                          ))
                        : null}
                    </ul>
                    <ul class={existingLabels}>
                      {(labels || []).map(l => (
                        <li>
                          {l}
                          <button
                            class={deleteLabelButton}
                            onClick={() => {
                              this.setState({
                                labels: labels.filter(el => el !== l)
                              });
                            }}
                            type="button"
                          >
                            <i class="fas fa-times"></i>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </Downshift>
              <div class={formGroup}>
                <label class={required}>
                  <span>Message Template Type</span>
                  <select
                    onChange={({ target }) =>
                      this.setState({
                        type: (target as HTMLSelectElement).value
                      })
                    }
                    name="type"
                    placeholder="Select One"
                    required
                  >
                    <option value="">Select One</option>
                    <option value={CampaignType.Email}>Email</option>
                    <option value={CampaignType.Mobile}>
                      Mobile push notification
                    </option>
                    <option value={CampaignType.Web}>Web message</option>
                  </select>
                </label>
              </div>
              {errorMessage && <div>{errorMessage}</div>}
              <button
                class={`${blue} ${createButton}`}
                disabled={!name || !type}
                type="submit"
              >
                Create
              </button>
            </form>
          </div>
        )}
      </Mutation>
    );
  }
}

export default withApollo(NewCampaign);
