import { h } from 'preact';
import { disabled, label, upload } from './style.css';

interface Props {
  accept: string;
  loading?: boolean;
  multiple: boolean;
  tooltip: string;
  title: string;
  onChange: (e: any) => void;
}

const UploadButton = ({
  accept,
  loading,
  multiple,
  tooltip,
  title,
  onChange
}: Props) => (
  <label
    class={`${label} ${loading ? disabled : ''}`}
    title={tooltip || 'Upload files'}
  >
    <div>
      {loading ? 'Upload in progress' : title}&nbsp;&nbsp;
      <i class={`fas ${loading ? 'fa-sync fa-spin' : 'fa-upload'}`} />
    </div>
    <input
      accept={accept}
      class={upload}
      disabled={loading}
      multiple={multiple}
      onChange={onChange}
      type="file"
    />
  </label>
);

export default UploadButton;
