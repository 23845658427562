import { h } from 'preact';
import { active, campaignLink, container, inactive, table } from './style.css';
import { CampaignStatus } from '../../../shared/constants';
import dateFormatter from '../../../shared/date-formatter';

interface Props {
  campaigns: {
    campaignId: string;
    createdAt: string;
    name: string;
    status: CampaignStatus;
    updatedAt: string;
  }[];
}

export default ({ campaigns }: Props): JSX.Element => (
  <div class={container}>
    <table class={table}>
      <tr>
        <th>Campaign Name</th>
        <th>Status</th>
        <th>Created</th>
        <th>Updated</th>
      </tr>
      {campaigns.map(c => (
        <tr>
          <td>
            <a class={campaignLink} href={`/campaigns/${c.campaignId}`}>
              {c.name}
            </a>
          </td>
          <td class={c.status === 'archived' ? inactive : active}>
            {c.status === 'archived' ? 'Archived' : 'Active'}
          </td>
          <td>{dateFormatter.format(new Date(c.createdAt))}</td>
          <td>{dateFormatter.format(new Date(c.updatedAt))}</td>
        </tr>
      ))}
    </table>
  </div>
);
