import { h, Component } from 'preact';
import { withApollo } from 'react-apollo';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';

import { primaryInverse } from '../../shared-styles/buttons.css';
import { missing, noPadding } from '../../shared-styles/general.css';
import {
  fullWidth,
  responsiveTable,
  table
} from '../../shared-styles/table.css';
import { GET_CAMPAIGN } from '../../../queries/campaign';
import { CampaignStatus } from '../../../shared/constants';
import Alert, { Type } from '../../../components/alert';

import OutputFileLink from '../../templates/output-file-link';

const EXECUTE_CAMPAIGN = gql`
  mutation executeCampaign($campaignId: String!) {
    executeCampaign(campaignId: $campaignId) {
      campaignExecutionId
    }
  }
`;

interface Props {
  campaignId: string;
}

interface State {
  lastUpdatedAt: string | null;
}

class ExecuteCampaign extends Component<Props, State> {
  public render({ campaignId }, { lastUpdatedAt }) {
    return (
      <Query query={GET_CAMPAIGN} variables={{ campaignId }}>
        {({ data, error, loading, startPolling, stopPolling }) => {
          if (loading) {
            return null;
          }
          const { campaign } = data;
          if (
            lastUpdatedAt !== campaign.updatedAt &&
            campaign.status === CampaignStatus.Executed
          ) {
            stopPolling();
            if (lastUpdatedAt) {
              this.setState({ lastUpdatedAt: null });
            }
          }
          return (
            <Mutation
              mutation={EXECUTE_CAMPAIGN}
              onCompleted={() => startPolling(5000)}
              onError={e => console.log('campaign errored!', e)}
              variables={{ campaignId }}
            >
              {mutation => (
                <div>
                  {lastUpdatedAt ? (
                    <Alert type={Type.Info}>
                      <div>
                        Campaign is running <i class="fas fa-cog fa-spin" />
                      </div>
                    </Alert>
                  ) : (
                    <div>
                      <div class={responsiveTable}>
                        <table class={`${table} animated fadeIn`}>
                          <tr>
                            <th>Template ID</th>
                            <th>Processed at</th>
                            <th>Output file</th>
                          </tr>
                          {(campaign.templates || []).map(t => (
                            <tr>
                              <td>{t.templateId}</td>
                              <td>
                                {t.processedAt &&
                                  new Date(t.processedAt).toLocaleString()}
                              </td>
                              <td
                                class={`${fullWidth} ${
                                  t.outputUrl ? noPadding : missing
                                }`}
                              >
                                {t.outputUrl ? (
                                  <OutputFileLink
                                    isOutputCurrent={t.isOutputCurrent}
                                    outputUrl={t.outputUrl}
                                    templateId={t.templateId}
                                  />
                                ) : (
                                  'Missing'
                                )}
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                      <button
                        class={primaryInverse}
                        disabled={!!lastUpdatedAt}
                        onClick={() => {
                          mutation();
                          this.setState({
                            lastUpdatedAt: campaign.updatedAt
                          });
                        }}
                      >
                        Execute campaign
                      </button>
                    </div>
                  )}
                </div>
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default withApollo(ExecuteCampaign);
