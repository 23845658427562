const debounce = (f, delay = 300) => {
  let handle;

  return function() {
    clearTimeout(handle);
    handle = setTimeout(() => {
      handle = null;
      f.apply(this, arguments);
    }, delay);
  };
};

export default debounce;
