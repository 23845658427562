import { h, cloneElement, Component, ComponentChildren } from 'preact';

import { toggleGroup } from './style.css';

interface Props {
  children: ComponentChildren;
  onStateChanged: (selectedTag: string | null) => void;
  tagIfNull?: string;
}

interface State {
  selectedTag: string | null;
}

export default class ToggleGroup extends Component<Props, State> {
  public constructor(props) {
    super(props);

    this.state = {
      selectedTag: props.tagIfNull || null
    };
  }

  private onToggle(sender, checked) {
    const { onStateChanged, tagIfNull } = this.props;
    const selectedTag = checked ? sender : tagIfNull || null;
    this.setState({ selectedTag }, () => onStateChanged(selectedTag));
  }

  public render({ children }, { selectedTag }) {
    const togglers = children.map(child =>
      cloneElement(child, {
        onToggle: (s, c) => this.onToggle(s, c),
        checked: child.props.tag === selectedTag
      })
    );

    return <div class={toggleGroup}>{togglers}</div>;
  }
}
