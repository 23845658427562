import { h, Component, ComponentChild } from 'preact';
import { route } from 'preact-router';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import { container } from '../../components/shared-styles/container.css';
import { form, formGroup } from '../../components/shared-styles/form.css';
import { primary } from '../../components/shared-styles/buttons.css';
import { setToken } from '../../shared/token';

const REGISTER = gql`
  mutation register($registration: Registration!) {
    register(registration: $registration) {
      token
    }
  }
`;

interface State {
  company: string;
  email: string;
  errorMessage: string;
  name: string;
  password: string;
}

export default class Register extends Component<{}, State> {
  private handleSubmit = (e, mutation): void => {
    e.preventDefault();
    mutation();
  };

  private confirm({ register }): void {
    setToken(register);
    route('/campaigns');
  }

  private error(): void {
    this.setState({
      errorMessage: 'Registration failed'
    });
  }

  public render(): ComponentChild {
    const { company, email, errorMessage, name, password } = this.state;
    return (
      <Mutation
        mutation={REGISTER}
        onCompleted={(data): void => this.confirm(data)}
        onError={(data): void => this.error(data)}
        variables={{ registration: { company, email, name, password } }}
      >
        {(mutation: Mutation): ComponentChild => (
          <div class={container}>
            <form
              class={form}
              method="post"
              onSubmit={(e): void => this.handleSubmit(e, mutation)}
            >
              <h1>Register</h1>
              <div class={formGroup}>
                <label>
                  Name
                  <input
                    autofocus
                    name="name"
                    onChange={(e): void =>
                      this.setState({
                        name: (e.target as HTMLInputElement).value
                      })
                    }
                    required
                    type="text"
                    value={this.state.name}
                  />
                </label>
              </div>
              <div class={formGroup}>
                <label>
                  Company
                  <input
                    name="company"
                    onChange={(e): void =>
                      this.setState({
                        company: (e.target as HTMLInputElement).value
                      })
                    }
                    required
                    type="text"
                    value={this.state.company}
                  />
                </label>
              </div>
              <div class={formGroup}>
                <label>
                  Email
                  <input
                    name="email"
                    onChange={(e): void =>
                      this.setState({
                        email: (e.target as HTMLInputElement).value
                      })
                    }
                    required
                    type="email"
                    value={this.state.email}
                  />
                </label>
              </div>
              <div class={formGroup}>
                <label>
                  Password
                  <input
                    name="password"
                    onChange={(e): void =>
                      this.setState({
                        password: (e.target as HTMLInputElement).value
                      })
                    }
                    required
                    type="password"
                    value={this.state.password}
                  />
                </label>
              </div>
              {errorMessage && <div>{errorMessage}</div>}
              <button class={primary} type="submit">
                Register
              </button>
            </form>
          </div>
        )}
      </Mutation>
    );
  }
}
