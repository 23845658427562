import gql from 'graphql-tag';

export const TEMPLATE_FIELDS = gql`
  fragment templateFields on Template {
    body
    createdAt
    createdBy
    dataFile
    dataPreview
    isOutputCurrent
    campaignId
    outputUrl
    processedAt
    templateId
    updatedAt
    updatedBy
  }
`;
