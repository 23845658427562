import { h } from 'preact';
import {
  forgot,
  fullWidthStripe,
  headContainer,
  home,
  input,
  logo,
  main,
  phojiLink,
  request,
  rightContainer,
  subnavContainer,
  subnavRequestAccount,
  topnav
} from './style.css';
import { formHorizontal } from '../../components/shared-styles/form.css';
import { blue } from '../../components/shared-styles/buttons.css';
import wordLogo from '../../assets/images/phoji-word-logo-blue.png';
import group from '../../assets/images/phoji-group.png';

const Home = () => (
  <div class={home}>
    <header class={headContainer}>
      <img class={logo} src={wordLogo} />
      <div class={rightContainer}>
        <nav>
          <ul class={topnav}>
            <li>Privacy</li>
            <li>Terms</li>
            <li>
              <a class={request} href="">
                Request an Account
              </a>
            </li>
          </ul>
        </nav>
        <form class={formHorizontal}>
          <input class={input} placeholder="Username" type="text" />
          <input class={input} placeholder="Password" type="password" />
          <button class={blue}>Sign In</button>
        </form>
        <div class={forgot}>
          Forgot Your{' '}
          <a class={phojiLink} href="">
            Username
          </a>{' '}
          or{' '}
          <a class={phojiLink} href="">
            Password?
          </a>
        </div>
      </div>
    </header>
    <section class={fullWidthStripe}>
      <div class={subnavContainer}>
        <nav>
          <ul>
            <li>
              <a href="">How it Works</a>
            </li>
            <li>
              <a href="">The Team</a>
            </li>
            <li>
              <a class={subnavRequestAccount} href="">
                Request an Account
              </a>
            </li>
          </ul>
        </nav>
        <form class={formHorizontal}>
          <input
            class={input}
            placeholder="Search..."
            style="margin-right: 0;"
          />
        </form>
      </div>
    </section>
    <section class={main}>
      <h1>What's Next in Text</h1>
      <p>
        With Phoji, you leverage our patented technology to create custom emojis
        from your own images. From there, insert them into your own emails, web
        messaging, mobile applications, and notifications, and more. We offer
        businesses and brands Phojis as a Software as a Service to integreate
        with easily, enabling you to customize your communications to the
        individual in a scalable, automated fashion.
      </p>
    </section>
  </div>
);

export default Home;
