import gql from 'graphql-tag';

const CAMPAIGN_QUERY = gql`
  query CampaignsQuery {
    campaigns {
      campaignId
      createdAt
      name
      status
      updatedAt
    }
  }
`;

export default CAMPAIGN_QUERY;
