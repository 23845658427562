import { h } from 'preact';
import { progress } from './style.css';

interface Props {
  max?: number;
  value: number;
}

export default ({ max = 100, value }: Props) => (
  <progress class={progress} max={max.toString()} value={value.toString()} />
);
