import { tokenPayload } from './token';

interface User {
  companyId: string;
  companyName: string;
  email: string;
  name: string;
  profileUrl?: string;
}

export default (): User | null => {
  const payload = tokenPayload();
  if (!payload) {
    return null;
  }
  return payload;
};
