import { h, render } from 'preact';
import './style';
import App from './components/app';

declare const module: any;

const mountNode = document.getElementById('app');

render(<App />, mountNode, mountNode.lastChild as Element);

if (module.hot) {
  module.hot.accept();
}
