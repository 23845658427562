import { h } from 'preact';
import Tile from '../tile';
import { container } from './style.css';
import { CampaignStatus } from '../../../shared/constants';

interface Props {
  campaigns: {
    campaignId: string;
    createdAt: string;
    name: string;
    status: CampaignStatus;
    updatedAt: string;
  }[];
}

export default ({ campaigns }: Props): JSX.Element => (
  <ul class={container}>
    {campaigns.map(c => (
      <li>
        <Tile {...c} />
      </li>
    ))}
  </ul>
);
