import { h, Component } from 'preact';
import { route } from 'preact-router';
import { company, dropdown, name, nameContainer, userMenu } from './style.css';
import profileDefault from '../../assets/images/profile-default.png';
import currentUser from '../../shared/current-user';
import { deleteToken } from '../../shared/token';

interface Props {
  classes: string[];
}

interface State {
  showDropdown: boolean;
}

export default class UserMenu extends Component<Props, State> {
  public static defaultProps = {
    classes: []
  };

  public logout(e) {
    e.preventDefault();
    deleteToken();
    window.location.href = '/login';
  }

  public render({ classes }, { showDropdown }) {
    const user = currentUser();
    return (
      <div class={classes.join(' ')}>
        <button
          class={userMenu}
          onClick={e => this.setState({ showDropdown: !showDropdown })}
        >
          <div class={nameContainer}>
            <div class={name}>{user.name}</div>
            <div class={company}>{user.companyName}</div>
          </div>
          <img alt="Default profile" src={profileDefault} />
        </button>
        {showDropdown && (
          <div class={dropdown}>
            <ul>
              <li>
                <a href="">My Account</a>
              </li>
              <li>
                <a href="">My Organization</a>
              </li>
              <li>
                <a href="">Send Feedback</a>
              </li>
              <li>
                <a href="/logout" onClick={this.logout}>
                  Logout
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    );
  }
}
