import { h } from 'preact';
import Match from 'preact-router/match';

const Link = ({ activeClassName, class: c, path, partial, ...props }) => (
  <Match path={path}>
    {({ matches, path: p }) => (
      <a
        {...props}
        class={
          matches || (partial && p.startsWith(partial)) ? activeClassName : c
        }
      />
    )}
  </Match>
);

export default Link;
