import gql from 'graphql-tag';

export const CAMPAIGN_FIELDS = gql`
  fragment campaignFields on Campaign {
    campaignId
    name
    status
    templates {
      body
      createdAt
      createdBy
      dataFile
      dataPreview
      isOutputCurrent
      campaignId
      outputUrl
      processedAt
      templateId
      updatedAt
      updatedBy
    }
    updatedAt
  }
`;

export const GET_CAMPAIGN = gql`
  query CampaignQuery($campaignId: String!) {
    campaign(campaignId: $campaignId) {
      ...campaignFields
    }
  }
  ${CAMPAIGN_FIELDS}
`;
